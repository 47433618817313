import FadeIn from "../fade-in/FadeIn"
import React from "react"
import * as styles from "./ContentContainer.module.scss"

/**
 * Returns a styled wrapper for article-content
 * @param {children} array The content to be wrapped
 *
 */

const ContentContainer = ({ children }) => {
  return (
    <FadeIn>
      <div className={styles.content}>{children}</div>
    </FadeIn>
  )
}

export default ContentContainer
