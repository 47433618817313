import React, { useState } from "react"

import VisibilitySensor from "react-visibility-sensor"
import classNames from "classnames"
import * as styles from "./FadeIn.module.scss"

/**
 * Returns a styled wrapper that fades in a component when it comes to view
 * @param {children} array The content to be wrapped
 *
 */

const FadeIn = ({ children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <VisibilitySensor
      partialVisibility={true}
      onChange={isVisible => {
        if (isVisible) {
          setVisible(true)
        }
      }}
    >
      <div
        className={classNames(styles.content, { [styles.visible]: visible })}
      >
        {children}
      </div>
    </VisibilitySensor>
  )
}

export default FadeIn
