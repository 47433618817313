import { arrow_down as ArrowDown } from "dnb-ui-lib/icons"
import { Icon } from "dnb-ui-lib/components"
import React from "react"
import * as styles from "./ScrollInstruction.module.scss"

/**
 * Returns a component that displays an animated arrow to show users they can scroll down
 */

const ScrollInstruction = () => {
  return (
    <div className={styles.arrowContainer}>
      <div className={styles.prompt}></div>
      <Icon
        icon={ArrowDown}
        size={"medium"}
        className={styles.arrow}
        aria-hidden
      />
    </div>
  )
}

export default ScrollInstruction
